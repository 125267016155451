import { Col, Row } from "@ims-systems-00/ims-ui-kit";
import CourseTile from "../../../components/CourseTile";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useTour } from "../../../components/Tour";
import { useNavigate } from "react-router-dom";
import CC_CONSTANTS from "../../../constants";
import useCourses from "../../../hooks/courses";
import Title from "../../../components/Title";
import Article from "../../../components/Article";
import SubTitle from "../../../components/SubTitle";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// function Guidelines() {
//   const { startTour } = useTour();
//   const courses = useCourses();
//   return (
//     <div className="guidelines-container">
//       <Row>
//         {/* <Col md="4">
//           {Object.keys(courses).map((course) => {
//             return (
//               <CourseTile
//                 stepCounts={courses[course].steps.length}
//                 name="Navigation system"
//                 description="Learn how to naviagate through the system with simple an interactive steps."
//                 onStart={() => {
//                   startTour(courses[course]);
//                 }}
//               />
//             );
//           })}
//         </Col> */}

//         <Col md="5" className="mx-auto">
//           <Title>Guidelines for the Carbon Calculator</Title>
//           <Article>
//             We hope that this powerful tool will help your organization report
//             greenhouse gas emissions in line with GHG Protocol and ISO14064-1.
//           </Article>
//           <SubTitle>Getting started</SubTitle>
//           <Article>
//             When you login to the tool, on the left-hand side of the screen, you
//             will see this menu:
//           </Article>

//           <Title>Dashboard</Title>

//           <Article>
//             Your dashboard gives a complete overview of your carbon emissions.
//             It helps you track, analyze, and manage your environmental impact by
//             consolidating data into easy-to-understand visuals like charts,
//             graphs, and metrics. Shows the total carbon emissions, usually in
//             CO₂ equivalents (CO₂e), giving an overview of your organization's
//             overall carbon impact over a defined period (e.g., annually,
//             monthly). Refers to the number of emissions categories relevant to
//             your organization's carbon-producing activities that you have
//             selected from the emissions categories tab. Custom Factors These are
//             emission factors created specifically for a particular process or
//             activity carried out by an organization, rather than using default
//             or standardized emission factors. For example, a company might
//             develop a custom emission factor for a specific manufacturing
//             process that is more efficient and produces fewer emissions than the
//             industry average. Biogenic Emissions These are part of the
//             short-term carbon cycle and can be "carbon-neutral" if the biomass
//             is managed sustainably. Fossil fuel emissions, on the other hand,
//             represent a long-term addition to atmospheric carbon.
//             Differentiating them allows for more accurate carbon accounting,
//             supports sustainable land-use policies, and helps track progress in
//             reducing fossil fuel dependence and emissions. Emission Insights
//             Breaks down emissions into Scope 1, Scope 2, and Scope 3 categories.
//             Pie Chart Provides a detailed view of emissions broken down by
//             categories such as energy consumption (electricity, gas),
//             transportation (fleet vehicles, flights), waste, water usage, and
//             purchased goods. It helps users identify the largest contributors to
//             their carbon footprint, facilitating targeted reductions. Net Zero
//             Target This is the year you aim to reach Net Zero. Carbon Reduction
//             Ambitions This represents your selected level of reduction of
//             emissions that your organization is committed to.
//           </Article>

//           <Title>Report Settings</Title>
//           <SubTitle>Overview Tab</SubTitle>
//           <SubTitle>Start and End Dates</SubTitle>
//           <Article>
//             The tool allows you to track your emissions monthly and yearly.
//             Select data from the most recent 12-month period; it is important to
//             select a reporting year that aligns with your company's financial
//             year.
//           </Article>
//           <SubTitle>Reporting Year</SubTitle>
//           <Article>
//             Please choose the latest Reporting Year to ensure the most up to
//             date data. This year will be featured in the automated reports later
//             in the spreadsheet, and it can be updated when new reporting years
//             are completed.
//           </Article>

//           <SubTitle>Base Year</SubTitle>
//           <Article>
//             The Base Year is crucial as a stable reference point for the
//             Reporting Company's operations, including data availability and
//             organisational structure. Emissions targets will be compared to the
//             Base Year, so this year should remain stable and will not change.
//             However, they may be recalculated as the business develops new
//             targets.
//           </Article>
//         </Col>
//       </Row>
//     </div>
//   );
// }

function Guidelines() {
  const [numPages, setNumPages] = useState();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div className="guidelines-container">
      <Row>
        <Col md="8" className="mx-auto">
          <center>
            <Document
              className={"mx-auto"}
              file="/guidelines.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => {
                let pageNum = index + 1;
                return (
                  <div>
                    <Page scale={1.2} className="" pageNumber={pageNum} />
                    {/* <p className="text-center">{pageNum}</p> */}
                  </div>
                );
              })}
            </Document>
          </center>
        </Col>
      </Row>
    </div>
  );
}

export default Guidelines;
