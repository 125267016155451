import { Button, Form } from "@ims-systems-00/ims-ui-kit";
import React, { useEffect } from "react";
import TextInput from "../../../components/TextInput";
import {
  getLocationFormState,
  locationFormValidaionSchema,
} from "./dto/location.dto";
import SelectInput from "../../../components/SelectInput";
import CC_CONSTANTS from "../../../constants";
import SelectPlaceInput from "../../../components/SelectPlaceInput";
import { useForm } from "@ims-systems-00/ims-react-hooks";
function LocationForm({ location = null, onSubmit = async function () {} }) {
  const {
    dataModel,
    initiateDataModel,
    validationErrors,
    handleChange,
    handleSubmit,
    isBusy,
    isFormValid,
  } = useForm(getLocationFormState(), locationFormValidaionSchema);
  useEffect(() => {
    initiateDataModel(getLocationFormState(location));
  }, [location]);
  return (
    <Form>
      <TextInput
        label="Location Name"
        mandatorySymbol
        placeholder="e.g. Head Office"
        value={dataModel.locationRef}
        error={validationErrors.locationRef}
        onChange={(changes) =>
          handleChange({
            field: "locationRef",
            value: changes.currentTarget.value,
          })
        }
      />
      <SelectPlaceInput
        label="Address"
        mandatorySymbol
        placeholder="e.g., 1234 Elm St, Springfield, IL 62704"
        value={dataModel.address}
        error={validationErrors["address.value"]}
        onSelect={(changes) =>
          handleChange({
            field: "address",
            value: changes,
          })
        }
      />
      <TextInput
        type="textarea"
        placeholder="Describe the activities conducted at this location"
        label="Description of activities"
        value={dataModel.descriptionOfActivities}
        error={validationErrors.descriptionOfActivities}
        onChange={(changes) =>
          handleChange({
            field: "descriptionOfActivities",
            value: changes.currentTarget.value,
          })
        }
      />
      <SelectInput
        label="GHG assessment inclusion"
        mandatorySymbol
        placeholder="e.g., Inlcude"
        value={dataModel.ghgAssessmentInclusion}
        error={validationErrors["ghgAssessmentInclusion.value"]}
        options={Object.values(CC_CONSTANTS.CC_GHG_INCLUSION_ASSESSMENT).map(
          (i) => ({
            value: i,
            label: i,
          })
        )}
        onChange={(changes) =>
          handleChange({
            field: "ghgAssessmentInclusion",
            value: changes,
          })
        }
      />
      <TextInput
        type="textarea"
        placeholder="Additional comments or notes"
        label="Comment"
        value={dataModel.comment}
        error={validationErrors.comment}
        onChange={(changes) =>
          handleChange({
            field: "comment",
            value: changes.currentTarget.value,
          })
        }
      />
      <Button
        disabled={!isFormValid()}
        color={"primary"}
        onClick={(e) => {
          handleSubmit(e, onSubmit);
        }}
      >
        {location ? (
          <React.Fragment>
            {!isBusy ? "Update location" : "Updating..."}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isBusy ? "Create location" : "Creating..."}
          </React.Fragment>
        )}
      </Button>
    </Form>
  );
}

export default LocationForm;
